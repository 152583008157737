<script setup lang="ts">
import CancelEventModal from '@/components/CancelEventModal.vue';
import DeleteEventModal from '@/components/DeleteEventModal.vue';
import DuplicateEventModal from '@/components/DuplicateEventModal.vue';
import GroupContactCreateSection from '@/components/Groups/partials/GroupContactCreateSection.vue';
import GroupEventSectionWrapper from '@/components/Groups/partials/GroupEventSectionWrapper.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import VenueSelectorNew from '@/components/VenueSelectorNew.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { getKey } from '@/util/globals';
import { copyObject } from '@/util/object-helpers';
import { getRoute } from '@/util/route';
import {
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  stampIsPast,
  stampIsValid,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import { dateFormat } from '@/variables/date-format';
import moment from 'moment';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import { groupRecurringDates } from '@/helpers/recurringFunctions';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { EventResource } from '@/types/event';

type Props = {
  eventId: number;
};
const props = defineProps<Props>();
const emits = defineEmits(['closed', 'cancelled', 'deleted', 'updated', 'duplicated']);

const showForEventId = ref(props.eventId);

const event = ref<null | EventResource>(null);
const initEvent = ref(null);
const initVenueInvite = ref(null);
const venueInvite = ref(null);
const recurringOriginalId = ref(null);
const owner = ref<null | {
  id: number;
  class: string;
  name: string;
  slug: string;
  has_partners: boolean;
  has_meta_data: boolean;
  has_shifts: boolean;
  has_boards: boolean;
  has_running_orders: boolean;
  has_forms: boolean;
  has_document_requests: boolean;
}>(null);
const hasRecurringEvents = ref(false);
const invitedUserEmails = ref([]);
const rooms = ref([]);
const contacts = ref([]);
const venue = ref(null);
const open = ref(true);
const workingOnRecurring = ref(false);
const recurring_dates = ref([]);
const usingModules = ref(null);

const orderedDates = computed(() => _.orderBy(recurring_dates.value));
const sortedRecurringDates = computed(() => _.orderBy(groupRecurringDates(orderedDates.value)));

const existing_dates = ref([]);

const fetchEvent = async () => {
  const { data } = await axios.get(`/api/events/${showForEventId.value}/edit-event`);
  event.value = copyObject(data.event);
  initEvent.value = copyObject(data.event);
  venue.value = copyObject(data.event.venue);
  venueInvite.value = copyObject(data.venueInvite);
  initVenueInvite.value = copyObject(data.venueInvite);
  recurringOriginalId.value = copyObject(data.recurringOriginalId);
  hasRecurringEvents.value = copyObject(data.hasRecurringEvents);
  owner.value = copyObject(data.owner);
  invitedUserEmails.value = copyObject(data.invitedUserEmails);
  rooms.value = copyObject(data.rooms);
  usingModules.value = copyObject(data.using_modules);

  contacts.value = data.event.contacts.map((c) => ({
    id: c.id,
    name: c.contactName,
    contact_id: c.contact_id,
    first_name: c.contact?.first_name,
    last_name: c.contact?.last_name,
    phone: c.contactPhone,
    country_code: c.contactCountryCode,
    email: c.contactEmail,
    role: c.role,
    contact_type: c.contact_type,
    write: false,
    invite: invitedUserEmails.value.includes(c.contactEmail),
  }));

  data.event.recurring_dates.forEach((date) => {
    if (date.id !== data.event.id) {
      recurring_dates.value.push(date.start_date);
      existing_dates.value.push(date.start_date);
    }
  });
};
fetchEvent();

const toast = useToast();

const loading = ref(false);
const pendingVenue = ref(false);
const is_global = ref(false);
const updateStartOfTimeObjects = ref(false);
const updateDurationOfTimeObjects = ref(false);
const showDuplicateModal = ref(false);
const showDeleteModal = ref(false);
const showCancelModal = ref(false);

const updateContact = (contact) => {
  contacts.value = contacts.value.map((c) => {
    if (c.id === contact.id) {
      return contact;
    }
    return c;
  });
};

const removeContact = (id: string | number) => {
  contacts.value = contacts.value.filter((c) => c.id !== id);
};

const venueProjectLeaderId = ref<number | null>(null);
const venueProjectLeaderName = ref<string | null>(null);
const changeDateType = ref('dates');
const hasBeenOneSecond = ref(false);
const makeItRecurring = ref(false);
const canEditTimeElements = computed(() => {
  return recurringOriginalId.value === null || recurringOriginalId.value === showForEventId.value;
});

const hasValidDates = computed(() => {
  return stampIsValid(event.value.start_date) && stampIsValid(event.value.end_date);
});

const canSave = computed(() => {
  if (!event.value) return false;
  const hasName = event.value.name.length;
  return [hasValidDates, hasName].every((v) => v);
});

const earliestEndDate = computed(() => {
  return event.value.start_date ? formatStampAsDate(event.value.start_date) : null;
});

const saveableEvent = computed(() => {
  return {
    name: event.value.name,
    description: event.value.description,
    start_date: formatStampAsDate(event.value.start_date),
    end_date: formatStampAsDate(event.value.end_date),
    contacts: contacts.value.map((c) => {
      const id = c.id.toString().startsWith('temp_') ? null : c.id;
      return {
        ...c,
        id,
        contact_id: c.contact_type !== 'App\\PartnerContact' ? null : c.contact_id,
        contact_type: c.contact_type !== 'App\\PartnerContact' ? null : c.contact_type,
      };
    }),

    has_boards: owner.value?.has_boards ? event.value.has_boards : true,
    has_forms: owner.value?.has_forms ? event.value.has_forms : true,
    has_running_orders: owner.value?.has_running_orders ? event.value.has_running_orders : true,
    has_document_requests: owner.value?.has_document_requests ? event.value.has_document_requests : true,
    requested_venue_id: venue.value === null ? null : venue.value.id,
    venue_project_leader_id: venueProjectLeaderId.value,
    is_global: is_global.value,
    update_start_of_time_objects: updateStartOfTimeObjects.value,
    update_duration_of_time_objects: updateDurationOfTimeObjects.value,
  };
});

setTimeout(() => {
  hasBeenOneSecond.value = true;
}, 1000);

const onSelectVenue = (data) => {
  venue.value = data[0];
  venueProjectLeaderId.value = data[1];
  venueProjectLeaderName.value = data[2];
};

const makeSureWantingToUnlink = async () => {
  if (loading.value) return;
  const certain = await useCertaintyModal().assertCertain(
    'Unlink Event',
    'Are yu sure you want to unlink this event? By doing so, it is no longer connected to the other recurrences, and changes will no longer be reflected. All elements on the event will be kept.',
    'Unlink'
  );
  if (!certain) return;
  loading.value = true;
  axios
    .post(`/api/recurring-events/${showForEventId.value}/unlink`)
    .then((resp) => {
      loading.value = false;
      useToast().success('Unlinked event, refreshing page');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
    .catch((error) => {
      loading.value = false;
      useToast().warning('Something went wrong, please try again. ');
      console.error(error);
    });
};

const updateEvent = async () => {
  const result = recurringOriginalId.value
    ? await useRecurringModal().recurringModal(
        '',
        'Update Recurring Event',
        'Do you want update all recurrences of the event, or just this one? <br> <strong>Note</strong>: All elements will be kept in the event itself either way. '
      )
    : false;
  if (result === 'cancel') return false;
  is_global.value = result === 'all';

  if (
    !timeStampsAreSame(event.value.start_date, initEvent.value.start_date, 'day') &&
    changeDateType.value === 'dates'
  ) {
    const updateAllTimeElements = await useMultipleButtonsModal().threeButtonModal({
      title: 'Start Date Updated',
      description: `The start date of this event has been updated (from ${formatStampAsDate(initEvent.value.start_date)}
                to ${formatStampAsDate(
                  event.value.start_date
                )}. Would you like to update all assignments, show times, running orders,
                room bookings (if you have any) and other time object accordingly?`,
      button: {
        text: 'Update',
        type: 'success',
      },
      options: [
        {
          value: 'first',
          label: 'Update all time objects',
        },
        {
          value: 'second',
          label: 'Update event date only',
        },
      ],
    });
    if (updateAllTimeElements === 'cancel') return;
    updateStartOfTimeObjects.value = updateAllTimeElements === 'first';
  } else {
    updateStartOfTimeObjects.value = false;
  }

  if (
    changeDateType.value === 'duration' &&
    (!timeStampsAreSame(event.value.start_date, initEvent.value.start_date, 'day') ||
      !timeStampsAreSame(event.value.end_date, initEvent.value.end_date, 'day'))
  ) {
    const updateAllDurationsOfElements = await useMultipleButtonsModal().threeButtonModal({
      title: 'Duration Changed',
      description: `The duration of this event has been updated. Would you like to update the production periods and room bookings (if you have any) accordingly?`,
      button: {
        text: 'Update',
        type: 'success',
      },
      options: [
        {
          value: 'first',
          label: 'Update all time objects ',
        },
        {
          value: 'second',
          label: 'Update event duration only',
        },
      ],
    });
    if (updateAllDurationsOfElements === 'cancel') return;
    updateDurationOfTimeObjects.value = updateAllDurationsOfElements === 'first';
  } else {
    updateDurationOfTimeObjects.value = false;
  }
  loading.value = true;

  try {
    const { data } = await axios.post(`/api/events/${showForEventId.value}`, saveableEvent.value);
    loading.value = false;

    await updateRecurringDates();
    useToast().success(`Event updated.`);
    setTimeout(() => {
      emits('updated', data);
      open.value = false;
    }, 1000);
  } catch (error) {
    loading.value = false;
    useToast().error('Could not update event.');
    console.error(error?.response?.data);
    throw error;
  }
};

const cancelEvent = () => {
  showCancelModal.value = false;
  nextTick(() => {
    showCancelModal.value = true;
  });
};

const openDeleteModal = () => {
  showDeleteModal.value = false;
  nextTick(() => {
    showDeleteModal.value = true;
  });
};

const openDuplicateEventModal = () => {
  if (recurringOriginalId.value || event.value.owner_type !== 'App\\Group') {
    return;
  }
  showDuplicateModal.value = false;
  nextTick(() => {
    showDuplicateModal.value = true;
  });
};

const duplicationDone = (date) => {
  emits('duplicated');
};

const eventHasBeenDeleted = () => {
  emits('deleted');
};

const eventHasBeenCancelled = ({ data }) => {
  open.value = false;
  emits('cancelled', data);
};

const openContactPage = (contactId: number) => {
  if (!event.value.owner.slug) {
    return;
  }
  switch (event.value.owner_type) {
    case 'App\\Group': {
      window.open(`${getRoute('groups.partners.contact', [event.value.owner.slug, contactId])}?edit=true`);
      break;
    }
    case 'App\\Festival': {
      window.open(`${getRoute('festivals.partnerContact.show', [event.value.owner.slug, contactId])}?edit=true`);
      break;
    }
    default: {
      console.error(`openContactPage Not Implemented for ${event.value.owner_type}`);
      break;
    }
  }
};

const revokeVenueInvite = async () => {
  if (!venueInvite.value) return;
  const certain = await useCertaintyModal().assertCertain(
    'Revoke Venue Invite',
    `Are you sure you want to revoke the invite of ${venue.value.name} as the venue of ${event.value.name}?`
    //
  );
  if (!certain) return;
  await axios.delete(`/api/venue-invites/${venueInvite.value.id}`);
  venueInvite.value = null;
  venue.value = null;
};

const startDateUpdated = (newVal, oldVal) => {
  event.value.start_date = newVal;

  if (moment(event.value.start_date).isBefore(moment())) {
    useToast().warning('This start date is in the past!');
  }
  switch (changeDateType.value) {
    case 'dates': {
      const diff = Math.abs(moment(initEvent.value.end_date).diff(initEvent.value.start_date, 'days'));
      event.value.end_date = moment(event.value.start_date).add(diff, 'days').format(dateFormat);
      break;
    }
    case 'duration': {
      break;
    }
  }
  if (moment(newVal, dateFormat).isAfter(event.value.end_date)) {
    event.value.end_date = event.value.start_date;
    useToast().warning('End date updated');
  }
};

const endDateUpdated = () => {
  if (stampIsPast(event.value.end_date)) {
    useToast().warning('This end date is in the past!');
  }
};

watch(changeDateType, () => {
  event.value.start_date = moment(initEvent.value.start_date).format(dateFormat);
  event.value.end_date = moment(initEvent.value.end_date).format(dateFormat);
});

const eventNameChanged = () => {
  if (event.value.name.length > 130) {
    useToast().success('Name Cannot be longer than 130 characters');
    event.value.name = event.value.name.substring(0, 130);
  }
};

const doTheActualDeletionOfEvent = async (date) => {
  workingOnRecurring.value = true;
  const index = _.findIndex(event.value.recurring_dates, (d) => d.start_date === date);
  if (index > -1) {
    const e = event.value.recurring_dates[index];
    await axios.delete(`/api/events/${e.id}`, {
      params: {
        is_global: false,
      },
    });
    useToast().success(`Event on ${formatStampAsDate(date)} has been deleted`);
    workingOnRecurring.value = false;
    const existingIndex = _.findIndex(existing_dates.value, (d) => d === date);
    if (existingIndex > -1) {
      existing_dates.value.splice(existingIndex, 1);
    }
    event.value.recurring_dates.splice(index);
  }
};

const checkToDeleteDate = async (date) => {
  if (existing_dates.value.includes(date)) {
    const text =
      `Are you sure you want to delete the event on ${formatStampAsDate(date)}? This can not ` +
      'be restored, and deletes all assignments, show times, bookings and invites for everyone involved.';
    const certain = await useCertaintyModal().assertCertain('Delete Event', text);
    if (!certain) return;
    await doTheActualDeletionOfEvent(date);
  }
  const index = _.findIndex(recurring_dates.value, (d) => d === date);
  if (index > -1) {
    recurring_dates.value.splice(index, 1);
  }
};

const addRecurringDate = (dates) => {
  if (dates.length === recurring_dates.value.length) return;

  if (dates.length > recurring_dates.value.length) {
    recurring_dates.value = dates;
    return;
  }

  const removedDate = recurring_dates.value.filter((d) => !dates.includes(d));
  if (removedDate.length !== 1) return;
  const date = removedDate[0];
  if (existing_dates.value.includes(date)) {
    checkToDeleteDate(date);
  } else {
    const index = _.findIndex(recurring_dates.value, (d) => d === date);
    if (index > -1) {
      recurring_dates.value.splice(index, 1);
    }
  }
};

const updateRecurringDates = async () => {
  const anyNewDates =
    recurring_dates.value.filter((d) => {
      return !existing_dates.value.includes(d);
    }).length > 0;

  if (!anyNewDates) {
    return;
  }
  workingOnRecurring.value = true;
  await axios
    .post(`/api/recurring-events/${event.value.id}`, {
      dates: recurring_dates.value,
    })
    .catch((error) => {
      workingOnRecurring.value = false;
      useToast().error('something went wrong.');
      console.error(error.response.data);
      throw error;
    });
  workingOnRecurring.value = false;
};
</script>

<template>
  <CrudSlideout
    v-if="open && event"
    title="Edit Event"
    :base-z-index="200"
    medium
    update
    :loading="loading"
    :header-actions="
      [
        !recurringOriginalId && event.owner_type === 'App\\Group'
          ? {
              title: 'Duplicate Event',
              primary: false,
              loading: loading,
              icon: 'fa-files-o  fa-regular',
              action: (e) => {
                openDuplicateEventModal();
              },
            }
          : null,
        {
          title: event.cancelled_at ? 'Reinstate Event' : 'Cancel Event',
          primary: false,
          loading: loading,
          icon: event.cancelled_at ? 'fa-ban' : 'fa-arrow-rotate-left',
          action: (e) => {
            cancelEvent();
          },
        },
      ].filter((i) => i !== null)
    "
    :disabled="!canSave"
    @update="updateEvent"
    @delete="openDeleteModal"
    @closed="[(open = false), $emit('closed')]">
    <div
      v-if="event"
      class="flex flex-1 flex-col divide-y">
      <div
        v-if="!canEditTimeElements"
        class="border-b">
        <div class="max-w-[800px] p-edge">
          <div class="italic">
            Dates and Show Times of this event cannot be edited here, because it is a child of a recurring events. To
            change these attributes of all recurrences of this event go
            <VButton
              size="extra-small"
              icon="fa-external-link"
              title="HERE"
              @click="[(showForEventId = recurringOriginalId), fetchEvent()]" />
            , or to change just this one unlink it further down.
          </div>
        </div>
      </div>

      <GroupEventSectionWrapper
        :serial-number="1"
        title="Update Information"
        :has-content="canSave"
        sub-title="Edit the name, assigning a venue or change the global start and end date for the event.">
        <template #content>
          <div class="form-layout gap-y-7 lg:grid-cols-2">
            <TextInput
              v-model="event.name"
              label="Name"
              :can-edit="!loading"
              placeholder="Name of event"
              @update:model-value="eventNameChanged" />

            <div>
              <div v-if="!venueInvite || venueInvite.accepted_at !== null">
                <VenueSelectorNew
                  :venue="venue"
                  :can-create="true"
                  @selected-venue="onSelectVenue" />
                <div
                  v-if="venueProjectLeaderName"
                  class="mt-3 flex items-center gap-3">
                  <InputLabel label="Project Leader" />
                  <p class="mb-[5px]">
                    {{ venueProjectLeaderName }}
                  </p>
                </div>
              </div>
              <div v-if="venueInvite && venueInvite.accepted_at === null && venueInvite.declined_at === null">
                <label>Venue</label>
                Requested: {{ venueInvite.venue.name }}. <br />
                <span v-if="venueInvite.project_leader != null">
                  Project Leader: {{ venueInvite.project_leader.name }}
                </span>
                <div
                  class="btn btn-delete btn-sm"
                  @click="revokeVenueInvite">
                  Revoke invite
                </div>
              </div>
            </div>

            <div class="form-layout col-span-2 grid-cols-3">
              <VDatepicker
                :model-value="event.start_date"
                label="Start Date"
                required
                :can-edit="canEditTimeElements && ['dates', 'duration'].includes(changeDateType)"
                @update:model-value="startDateUpdated($event, event.start_date)" />

              <VDatepicker
                v-model="event.end_date"
                label="End Date"
                tabindex="2"
                required
                :earliest-date="earliestEndDate"
                :can-edit="canEditTimeElements && ['duration'].includes(changeDateType)"
                @update:model-value="endDateUpdated" />
              <VSelect
                v-if="canEditTimeElements"
                v-model="changeDateType"
                label="Change Dates"
                :options="[
                  {
                    id: 'duration',
                    name: 'Change Duration',
                    title:
                      'Allows you to change start and end of event, but mainly used to add or remove dates to already existing period.',
                  },
                  {
                    id: 'dates',
                    name: 'Change Date(s)',
                    title:
                      'Allows you to move the event in time (when it starts), but the duration of the event will stay the same.',
                  },
                ]" />
            </div>

            <TextareaInput
              v-model="event.description"
              label="Description"
              :min-height="30"
              wrapper-class="col-span-2"
              placeholder="Say something about this event" />
          </div>
        </template>
      </GroupEventSectionWrapper>

      <GroupContactCreateSection
        :contacts="contacts"
        :tab-index="6"
        :serial-number="2"
        :has-partners="
          (event.owner_type === 'App\\Group' && owner && owner.has_partners) || event.owner_type === 'App\\Festival'
        "
        is-update
        :invited-emails="[]"
        :contact-model="owner.class.split('\\')[1]"
        :contact-model-id="owner.id"
        :contact-model-slug="owner.slug"
        @open-contact-page="openContactPage"
        @remove="removeContact"
        @update="updateContact"
        @add="contacts.push($event)" />

      <GroupEventSectionWrapper
        extra-content-class="gap-2 justify-around items-center"
        has-content
        :serial-number="3"
        title="Modules to show">
        <template #content>
          <div class="p-edge flex gap-2 flex-col max-w-[500px]">
            <SettingCheck
              v-if="owner && owner.has_boards"
              v-model="event.has_boards"
              :disabled="getKey(usingModules, 'boards', false)"
              label="Use boards"
              title="If enabled, the boards tab is available." />
            <SettingCheck
              v-if="owner && owner.has_forms"
              v-model="event.has_forms"
              :disabled="getKey(usingModules, 'forms', false)"
              label="Use forms"
              title="If enabled, the forms tab is available." />
            <SettingCheck
              v-if="owner && owner.has_running_orders"
              v-model="event.has_running_orders"
              :disabled="getKey(usingModules, 'runningorders', false)"
              label="Use Running Orders"
              title="If enabled, the running orders tab is available." />
            <SettingCheck
              v-if="owner && owner.has_document_requests"
              v-model="event.has_document_requests"
              :disabled="getKey(usingModules, 'documentrequests', false)"
              label="Use Document Requests"
              title="If enabled, the document request part of the document center is available." />
          </div>
        </template>
      </GroupEventSectionWrapper>

      <GroupEventSectionWrapper
        v-if="canSave && hasRecurringEvents"
        :actions="[
          recurringOriginalId !== null
            ? {
                title: 'Unlink From Recurring',
                icon: 'fa-link-slash',
                action: () => {
                  makeSureWantingToUnlink();
                },
              }
            : null,
        ]"
        extra-content-class="gap-2 justify-around items-center"
        :has-content="(recurringOriginalId || makeItRecurring) && recurring_dates.length > 0"
        :serial-number="4"
        :sub-title="event.name + ' is' + (recurringOriginalId ? '' : ' not') + ' recurring.'"
        title="Recurring Event">
        <template #content>
          <div
            v-if="canEditTimeElements && !recurringOriginalId && !makeItRecurring"
            class="pl-10">
            <span
              class="hover:underline cursor-pointer text-highlight font-bold"
              @click="makeItRecurring = true">
              Make {{ event.name }} recurring
            </span>
          </div>
          <div
            v-else-if="canEditTimeElements"
            class="grid lg:grid-cols-[2fr_3fr] max-w-[1000px] gap-5">
            <div class="flex w-[400px] flex-col gap-8">
              <div>
                <h3>Original Date:</h3>
                <p>
                  {{ formatStampAsDate(event.start_date) }}
                </p>
              </div>
              <div>
                <h3>Add Dates</h3>
                <VDatepicker
                  :model-value="recurring_dates"
                  multi-dates
                  class="[&>div]:bg-backgroundColor"
                  inline
                  :disabled-dates="[event.start_date]"
                  :start-date="event.start_date"
                  @update:model-value="addRecurringDate" />
              </div>
            </div>
            <div class="flex flex-1 flex-col gap-5">
              <h2 class="pl-edge">Recurring dates ({{ recurring_dates.length }})</h2>
              <div class="overflow-auto">
                <div v-for="month in sortedRecurringDates">
                  <h3 class="pl-edge">
                    {{ formatStampAsHumanReadableDate(month[0], 'MMMM YYYY') }}
                    <span class="text-highlight"> ({{ month.length }}/{{ recurring_dates.length }}) </span>
                  </h3>
                  <VTable edge-to-edge>
                    <VTableRow
                      v-for="date in month"
                      :key="date">
                      <VTableCell main-cell>
                        {{ formatStampAsDate(date) }}
                      </VTableCell>
                      <VTableCell
                        style="width: 60px"
                        title="Already an existing event">
                        <i
                          v-if="existing_dates.includes(date)"
                          class="fa fa-link" />
                      </VTableCell>
                      <VTableCell style="width: 60px">
                        <VButton
                          size="inTable"
                          :loading="workingOnRecurring"
                          icon="fa-trash"
                          @click="checkToDeleteDate(date)" />
                      </VTableCell>
                    </VTableRow>
                  </VTable>
                </div>
              </div>
            </div>
          </div>

          <!--          <div class="flex-1">-->
          <!--            <VButton-->
          <!--              :title="recurringOriginalId ? 'Edit Recurring Dates' : 'Make Recurring'"-->
          <!--              icon="fa-external-link"-->
          <!--              @click="openRoute(getRoute('events.edit.recurring', event.slug))"></VButton>-->
          <!--          </div>-->
        </template>
      </GroupEventSectionWrapper>

      <DuplicateEventModal
        v-if="showDuplicateModal"
        :event-id="event.id"
        :event-name="event.name"
        :has-partners="owner.has_partners"
        :has-meta-data="owner.has_meta_data"
        :has-shifts="owner.has_shifts"
        :is-venue="rooms.length > 0"
        :event-start-date="event.start_date"
        @duplicated="duplicationDone"
        @closed="[(showDuplicateModal = false), (open = false)]" />

      <DeleteEventModal
        v-if="showDeleteModal"
        :event-name="event.name"
        :event-id="event.id"
        :is-simple="false"
        :is-recurring="recurringOriginalId !== null"
        @deleting="loading = true"
        @closed="showCancelModal = false"
        @event-deleted="eventHasBeenDeleted" />

      <CancelEventModal
        v-if="showCancelModal"
        :event-name="event.name"
        :is-recurring="recurringOriginalId !== null"
        :is-cancelled="event.cancelled_at !== null"
        :event-id="event.id"
        @event-cancelled="eventHasBeenCancelled"
        @closed="showCancelModal = false" />
    </div>
  </CrudSlideout>
</template>
