<script setup lang="ts">
import { useEventTypes } from '@/composables/use-event-types';
import { useGroupProjectLeaders } from '@/composables/use-group-project-leaders';
import { EventMinimalResource } from '@/types/event';
import { InviteFestival, InviteResource } from '@/types/invite';
import { getRoute, openRoute } from '@/util/route';
import { router, usePage } from '@inertiajs/vue3';
import moment from 'moment';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { getItemFromArrayBasedOnId, uniqueObjectsFromArray } from '@/util/globals';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import IntermediateStepAssignments from '@/components/IntermediateStep/Sections/IntermediateStepAssignments.vue';
import IntermediateStepRoomBookings from '@/components/IntermediateStep/Sections/IntermediateStepRoomBookings.vue';
import IntermediateStepShifts from '@/components/IntermediateStep/Sections/IntermediateStepShifts.vue';
import IntermediateStepPartners from '@/components/IntermediateStep/Sections/IntermediateStepPartners.vue';
import VButton from '@/components/Inputs/VButton.vue';
import EventRequestSlideOut from '@/components/Models/EventRequests/EventRequestSlideOut.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import UserProfileModal from '@/components/Modals/UserProfileModal.vue';
import IntermediateStepProductionPeriod from '@/components/IntermediateStep/Sections/IntermediateStepProductionPeriod.vue';
import RecurringDatesButton from '@/components/Models/Recurring/RecurringDatesButton.vue';
import IntermediateStepStatusBar from '@/components/IntermediateStep/Sections/IntermediateStepStatusBar.vue';
import DisplayText from '@/components/Display/DisplayText.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import ShowTimeCRUDModal from '@/components/ShowTimes/ShowTimeCRUDModal.vue';
import IntermediateStepPerformanceSection from '@/components/IntermediateStep/Sections/IntermediateStepPerformanceSection.vue';

type Props = {
  invite: InviteResource;
  groupId: number;
  event: EventMinimalResource;
  admin: boolean;
  venueId: number | null;
  onEvent: boolean;
  performanceId: number | null;
  festival: InviteFestival | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  start: [];
  end: [];
  reloadInvite: [];
  editEvent: [];
  updateEventType: [arg: { key: string; value: number | string }];
  attributeUpdated: [arg: { key: string; value: number | string }];
  updateEvent: [arg: EventMinimalResource];
}>();

const toast = useToast();
const { recurringModal } = useRecurringModal();
const { threeButtonModal } = useMultipleButtonsModal();
const { assertCertain } = useCertaintyModal();
const { isSmallScreen } = useSmallScreen();

const { projectLeaders, fetchGroupProjectLeaders } = useGroupProjectLeaders(props.groupId, false);
fetchGroupProjectLeaders();

const { eventTypes, fetch: fetchEventTypes } = useEventTypes('Group', props.groupId, false);
fetchEventTypes();

const roomBookings = ref([]);

const accessLevels = computed(() =>
  props.invite.access_levels !== false
    ? props.invite.access_levels
    : {
        has_assignments: false,
        has_room_bookings: false,
        has_shifts: false,
        has_partners: false,
      }
);

const totalShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.declined_at === null).length;
});

const declinedShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.declined_at !== null).length;
});

const unapprovedShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => !s.approved).length;
});
const forSaleShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return (
    props.invite.owner_shifts.filter(
      (s) => s.for_sale && s.shift_interest_pivots.filter((p) => !p.declined_at).length > 0
    ).length +
    '/' +
    props.invite.owner_shifts.filter((s) => s.for_sale).length
  );
});

const unassignedShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.user_id === null).length;
});

const pendingShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter(
    (s) => s.accepted_at === null && s.declined_at === null && s.approved && s.user_id !== null
  ).length;
});

const cancelledShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.cancelled_at !== null).length;
});

const loading = ref(true);
const requesterModalOpen = ref(false);
const showAssignments = ref(true);
const showRoomBookings = ref(true);
const showShifts = ref(true);
const showPartners = ref(true);
const indexOfRecurringDate = ref<string | number>(0);
const workingItem = ref<string | null>(null);

const isNotOnAPartner = !window.location.href.includes('/contacts/');

const getIndexOfRecurringDate = () => {
  const array = props.event.recurring_dates
    .slice()
    .sort((a, b) => moment(a.start_date).valueOf() - moment(b.start_date).valueOf());

  const index = _.findIndex(array, (d) => d.start_date === props.invite.start_date);

  if (index > -1) {
    indexOfRecurringDate.value = index + 1;
  } else {
    indexOfRecurringDate.value = 'N/A';
  }
};

getIndexOfRecurringDate();

const showStatusbar = computed(() => {
  if (props.festival) return false;
  return (
    getItemFromArrayBasedOnId(props.invite.event_type_id, eventTypes.value, { status_bar: [] })?.status_bar?.length > 0
  );
});

const goToOtherEvent = (date: string) => {
  const otherInvite = props.invite.recurring_dates.find((i) => i.start_date === date);
  if (otherInvite) {
    window.location.href = getRoute('events.show', otherInvite.slug);
  } else {
    const otherEvent = props.event.find((i) => i.start_date === date);
    if (otherEvent) {
      throw new Error('Go to other event error');
    }
  }
};

const formattedDate = (date: string) => {
  if (moment(props.invite.event?.start_date).isSame(date, 'day')) {
    return null;
  }
  return moment(date).format(`ddd MMM Do${moment(props.invite.event?.start_date).isSame(date, 'year') ? '' : 'YYYY'}`);
};

const formattedDateTime = (date: string, secondDate?: string) => {
  if (secondDate) {
    const diff = Math.abs(moment(date).diff(secondDate, 'minutes'));
    if (diff < 60 * 24) {
      return moment(date).format('HH:mm');
    }
    return `${moment(date).format('HH:mm')}(${Math.round(diff) / 60}H)`;
  }
  return moment(date).format('HH:mm');
};

// const openPerformance = (performance, event) => {
//   if (props.performanceId && performance.id === props.performanceId) {
//     return;
//   }
//   if (event && event.ctrlKey) {
//     window.open(performance.slug);
//   } else {
//     router.visit(performance.slug);
//   }
// };

const resetItem = () => {
  workingItem.value = null;
};

const selectProjectLeader = async (newProjectLeaderId: number) => {
  if (newProjectLeaderId === props.invite.project_leader_id) return null;
  if (!props.invite.isAccepted) {
    emit('attributeUpdated', {
      key: 'project_leader_id',
      value: newProjectLeaderId,
    });
    return true;
  }
  workingItem.value = 'project_leader_id';

  const addToAll = props.invite.recurring_original_id
    ? await recurringModal(
        '',
        'Update Project Leader',
        'Do you want to update the event type on all recurrences of this event, or just this one?'
      )
    : false;
  if (addToAll === 'cancel') {
    resetItem();
    return;
  }

  await axios.patch(`/api/invites/${props.invite.id}/project-leader`, {
    project_leader_id: newProjectLeaderId,
    is_global: addToAll === 'all',
  });
  toast.success('Project Leader Changed.');
  emit('attributeUpdated', {
    key: 'project_leader_id',
    value: newProjectLeaderId,
  });
  resetItem();
  return;
};

const selectEventType = async (newEventTypeId: number) => {
  if (newEventTypeId === props.invite.event_type_id) return null;
  if (!props.invite.isAccepted) {
    emit('attributeUpdated', {
      key: 'event_type_id',
      value: newEventTypeId,
    });
    return true;
  }
  workingItem.value = 'event_type_id';

  const addToAll = props.invite.recurring_original_id
    ? await recurringModal(
        '',
        'Update Event Type',
        'Do you want to update the Event type on all recurrences of this event, or just this one?'
      )
    : false;
  if (addToAll === 'cancel') {
    resetItem();
    return;
  }

  const eventType = getItemFromArrayBasedOnId(newEventTypeId, eventTypes.value, { name: 'Event Type' });

  const result =
    newEventTypeId === null
      ? true
      : await threeButtonModal({
          title: 'Add Elements to Event',
          description: `Would you like to add all elements of ${eventType?.name} to event?`,
          button: {
            text: 'Update',
            type: 'success',
          },
          options: [
            {
              value: 'first',
              label: 'No',
            },
            {
              value: 'second',
              label: 'Yes',
            },
          ],
        });
  if (result === 'cancel') {
    resetItem();
    return;
  }

  await axios.patch(`/api/invites/${props.invite.id}/event-type`, {
    event_type_id: newEventTypeId,
    is_global: addToAll === 'all',
    add_elements: result === 'second',
  });

  toast.success('Event Type Updated.');
  emit('attributeUpdated', {
    key: 'event_type_id',
    value: newEventTypeId,
  });
  resetItem();
  return;
};

const getStatusOfInvite = () => {
  const eventType = getItemFromArrayBasedOnId(props.invite.event_type_id, eventTypes.value, { status_bar: [] });

  if (!eventType) return '';

  if (!eventType.status_bar) return '';

  if (props.invite.status_bar_number === null) return '';

  if (
    eventType.status_bar.length === 0 ||
    props.invite.status_bar_number === 0 ||
    eventType.status_bar.length < props.invite.status_bar_number
  ) {
    return 'N/A';
  }
  return eventType.status_bar[props.invite.status_bar_number - 1].name;
};

const updateStatusBarNumber = async (num: number) => {
  if (num === 0) {
    const c = await assertCertain('Reset Status', 'Are you sure you want to reset the status back to 0?');
    if (!c) return;
  }
  await axios.patch(`/api/invites/${props.invite.id}/status-bar-number`, {
    status_bar_number: num,
  });
  emit('attributeUpdated', {
    key: 'status_bar_number',
    value: num,
  });
  toast.success('Status Updated');
};

const makeSureWantingToUnlink = async () => {
  if (!props.admin) return;
  const certain = await useCertaintyModal().assertCertain(
    'Unlink Event',
    'Are you sure you want to unlink this event? By doing so, it is no longer connected to the other recurrences, and changes will no longer be reflected. All elements on the event will be kept. '
  );
  if (!certain) return;
  await axios.post(`/api/recurring-events/${props.invite.event?.id}/unlink`).catch((error) => {
    toast.warning('Something went wrong, please try again. ');
    console.error(error);
  });
  toast.success('Unlinked event, refreshing page');
  emit('reloadInvite');
};

const selectedEventRequestId = ref(null);
const openEventRequestForm = (eventRequestId) => {
  if (!eventRequestId) return;
  selectedEventRequestId.value = null;
  nextTick(() => {
    selectedEventRequestId.value = eventRequestId;
  });
};

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';

const isOwner = computed(() => {
  if (!props.invite.event) return false;
  return props.invite.event.owner_type === 'App\\Group' && props.invite.event.owner_id === props.groupId;
});

const editDescription = ref('');
const editModeDescription = ref(false);

const reloadEventPage = () => {
  router.reload({
    only: ['event', 'myGroupInvites'],
  });
};

const saveDescription = async () => {
  if (!props.admin || !isOwner.value) return;

  await axios.patch('/api/events/' + props.event.id + '/update-description', {
    description: editDescription.value,
  });

  if (props.onEvent) {
    router.reload({
      only: ['event', 'myGroupInvites'],
      onSuccess: () => {
        toggleEditModeDescription();
      },
    });
  } else {
    emit('updateEvent', { ...props.event, description: editDescription.value });
    toggleEditModeDescription();
  }
};

const toggleEditModeDescription = () => {
  if (!props.admin || !isOwner.value) return;

  if (editModeDescription.value) {
    editDescription.value = '';
    editModeDescription.value = false;
    return;
  }
  if (usePage().props.event?.description) {
    editDescription.value = usePage().props.event.description;
  } else if (props.event.description) {
    editDescription.value = props.event.description;
  } else {
    editDescription.value = '';
  }
  editModeDescription.value = true;
};

const descriptionActions = computed(() => {
  if (!props.admin || !isOwner.value) return;

  if (editModeDescription.value) {
    return [
      {
        title: 'Cancel',
        icon: 'fa-times',
        buttonClasses: 'btn-danger',
        action: () => {
          toggleEditModeDescription();
        },
      },
      {
        title: 'Save',
        icon: 'fa-save',
        buttonClasses: 'btn-success',
        action: () => {
          saveDescription();
        },
      },
    ];
  }

  return [
    {
      title: 'Edit',
      icon: 'fa-pencil',
      action: () => {
        toggleEditModeDescription();
      },
    },
  ];
});

const selectedShowTime = ref(null);

const statusBarOfEventType = () => {
  return getItemFromArrayBasedOnId(props.invite.event_type_id, eventTypes.value, { status_bar: [] })?.status_bar;
};
</script>

<template>
  <div
    :style="isSmallScreen ? 'height: calc( ' + height + ' - 180px)' : ''"
    class="grid h-full border-t gap-edge p-edge md:grid-cols-[300px_1fr] md:grid-rows-[100px_auto] md:border-none">
    <div
      v-if="showStatusbar && !isSmallScreen"
      class="!b-0 col-span-2 h-[90px]">
      <div class="change-fa-icon">
        <div class="flex items-center">
          <div class="mb-4 flex w-full items-center justify-between">
            <div class="flex items-center gap-1">
              <InputLabel
                label="Status: "
                class="!mb-0"
                super-text />
              <div class="text-sm font-semibold text-textColor">
                <span class="mx-3 text-lg text-highlight">
                  {{ props.invite.status_bar_number }}
                </span>
                <span class="text-base">
                  {{ getStatusOfInvite() }}
                </span>
              </div>
            </div>

            <VButton
              v-if="admin && invite.status_bar_number > 0"
              tool-tip-text="Reset the Status Bar counter to 0"
              size="inTable"
              icon="fa-rotate-left text-textColor-soft hover:text-textColor"
              @click.stop="updateStatusBarNumber(0)">
            </VButton>
          </div>
        </div>
        <IntermediateStepStatusBar
          v-if="statusBarOfEventType().length > 0"
          :status-bar="statusBarOfEventType()"
          :status="props.invite.status_bar_number"
          :can-edit="admin"
          @update:status="admin && $event !== invite.status_bar_number ? updateStatusBarNumber($event) : null" />
      </div>
    </div>
    <div class="flex flex-col gap-edge">
      <BoxContainer header="Details">
        <div class="flex flex-col gap-edge">
          <div v-if="invite.event.recurring_original_id && invite.event.recurring_dates">
            <RecurringDatesButton
              :recurring-dates="invite.event?.recurring_dates"
              :clickable="true"
              :should-emit="true"
              :event="invite.event"
              @change-date="goToOtherEvent">
              <template #button>
                <div
                  class="btn btn-success btn-outline btn-xs cursor-pointer"
                  :title="'Event ' + indexOfRecurringDate + ' of ' + invite.event.recurring_dates.length">
                  <i class="fa fa-chain fa-fw" /> Event {{ indexOfRecurringDate }} of
                  {{ event.recurring_dates.length }}
                </div>
              </template>
            </RecurringDatesButton>

            <VButton
              v-if="admin && invite.event.owner_type === 'App\\Group' && invite.event.owner_id === groupId"
              size="extra-small"
              icon="fa-chain-broken"
              title="Unlink Event"
              @click="makeSureWantingToUnlink" />
          </div>

          <div v-if="invite.hasOwnProperty('show_times') && invite.show_times.length >= 0">
            <div class="flex justify-between group mb-3">
              <InputLabel
                label="Shows"
                super-text />

              <VButton
                v-if="isOwner"
                type="info"
                class="btn-outline-text text-textColor-soft"
                size="tiny"
                title="Add"
                icon="fa-plus"
                @click="
                  () => {
                    selectedShowTime = null;
                    nextTick(() => {
                      selectedShowTime = { id: null };
                    });
                  }
                ">
                <span class="invisible group-hover:visible mr-2"> Add </span>
                <i class="fa fa-fw fa-plus group-hover/box:visible invisible" />

                <!--                <span class="invisible group-hover:visible mr-2"> Add </span>-->
                <!--                <i class="fa fa-fw fa-plus" />-->

                <!--                <i class="fa fa-fw fa-plus" />-->
                <!--                Add-->
              </VButton>
            </div>
            <div class="flex flex-col gap-6">
              <div
                v-for="(showTime, index) in invite.show_times"
                :key="showTime.id"
                class="flex gap-2 justify-between group">
                <div class="flex flex-col">
                  <div class="text-sm font-headers uppercase text-highlight">
                    {{ showTime.title ? showTime.title : 'Show Time #' + (index + 1) }}
                  </div>
                  <div
                    v-if="showTime.room"
                    class="text-sm text-textColor-soft">
                    {{ showTime.room }}
                  </div>
                  <div v-if="formattedDate(showTime.start)">
                    {{ formattedDate(showTime.start) }}
                  </div>
                  <div>
                    {{ formattedDateTime(showTime.start, null) }} -
                    {{ formattedDateTime(showTime.end, showTime.start) }}
                  </div>
                </div>
                <div
                  v-if="isOwner"
                  class="p-1 group">
                  <VButton
                    type="info"
                    class="btn-outline-text text-textColor-soft"
                    size="tiny"
                    title="Edit"
                    icon="fa-pencil"
                    @click="
                      () => {
                        selectedShowTime = null;
                        nextTick(() => {
                          selectedShowTime = showTime;
                        });
                      }
                    ">
                    <span class="invisible group-hover:visible mr-2"> Edit </span>
                    <i class="fa fa-fw fa-pencil group-hover/box:visible invisible" />
                  </VButton>
                </div>
              </div>
            </div>
          </div>

          <IntermediateStepProductionPeriod
            :invite="invite"
            :can-edit="admin"
            :group-id="groupId"
            :with-room-bookings="venueId !== null"
            @edit-event="$emit('editEvent')"
            @start="$emit('attributeUpdated', { key: 'start', value: $event })"
            @end="$emit('attributeUpdated', { key: 'end', value: $event })" />

          <div>
            <InputLabel
              label="Venue"
              super-text />
            <p>{{ event.venue.name }}</p>
          </div>

          <div>
            <InputLabel
              label="Owner"
              super-text />
            <p>{{ event.owner.name }}</p>
          </div>

          <div v-if="invite.requester && event.owner_type === 'App\\Group' && event.owner_id === groupId">
            <InputLabel
              label="Requester"
              super-text />
            <p
              class="flex cursor-pointer justify-between"
              @click.self="
                [
                  (requesterModalOpen = false),
                  $nextTick(() => {
                    requesterModalOpen = true;
                  }),
                ]
              ">
              {{ invite.requester.name }}
              <VButton
                v-if="admin && isNotOnAPartner && invite.event_request_id"
                size="extra-small"
                class="w-[30px]"
                icon="fa-external-link"
                @click="openEventRequestForm(invite.event_request_id)" />
            </p>
            <UserProfileModal
              v-if="requesterModalOpen"
              :name="invite.requester.name"
              :email="invite.requester.email"
              :phone="invite.requester.phone"
              :show-image="false"
              :avatar-url="null" />
          </div>

          <div
            v-if="!festival && projectLeaders && (admin || invite.project_leader_id)"
            class="flex flex-col [&>div>div>.multiselect-caret]:m-0 [&>div>div>.multiselect-single-label]:pl-0 [&>div>div>.multiselect-single-label]:pr-[10px] [&>div>div]:min-h-[0px]">
            <InputLabel
              label="Project Leader"
              super-text />
            <i
              v-if="workingItem === 'project_leader_id'"
              class="fa fa-fw fa-circle-o-notch fa-spin" />
            <VSelect
              v-else
              :model-value="invite.project_leader_id"
              :can-edit="admin"
              :is-hidden="true"
              option-value="name"
              nullable
              wrapper-class="mx-[-0.5rem] [&_.dropdown-element]:py-2"
              placeholder="-- Select --"
              nullable-display-text="N/A"
              :options="projectLeaders"
              @update:model-value="selectProjectLeader" />
          </div>

          <div
            v-if="!festival && eventTypes && (admin || invite.event_type_id)"
            class="flex flex-col [&>div>div>.multiselect-caret]:m-0 [&>div>div>.multiselect-single-label]:pl-0 [&>div>div>.multiselect-single-label]:pr-[10px] [&>div>div]:min-h-[0px]">
            <InputLabel
              label="Event Type"
              super-text />
            <i
              v-if="workingItem === 'event_type_id'"
              class="fa fa-fw fa-circle-o-notch fa-spin" />
            <VSelect
              v-else
              :model-value="invite.event_type_id"
              :can-edit="admin"
              is-hidden
              option-value="name"
              wrapper-class="mx-[-0.5rem] [&_.dropdown-element]:py-2"
              nullable
              nullable-display-text="N/A"
              placeholder="-- Select --"
              :options="eventTypes"
              @update:model-value="selectEventType">
              <template #singlelabel="{ value }">
                <div class="truncate-text w-full p-1 hover:bg-backgroundColor-content hover:text-textColor">
                  <i
                    class="fa fa-fw fa-tag"
                    :style="'color: ' + value.color" />
                  {{ value.name }}
                </div>
              </template>
              <template #default="{ option }">
                <div
                  class="truncate-text w-full p-1 hover:bg-backgroundColor-content hover:text-textColor"
                  :class="invite.event_type_id === option.id ? 'text-textColor' : 'text-textColor-soft'">
                  <i
                    class="fa fa-fw"
                    :class="invite.event_type_id === option.id ? 'fa-check' : 'fa-tag'"
                    :style="'color: ' + option.color" />
                  {{ option.name }}
                </div>
              </template>
            </VSelect>
          </div>

          <div v-if="showStatusbar && !isSmallScreen && invite.event_type_id">
            <InputLabel
              label="Status"
              super-text />
            {{ getStatusOfInvite() }}
            <div :id="'status_bar_wrapper_' + invite.id" />
          </div>
        </div>
      </BoxContainer>

      <BoxContainer
        v-if="accessLevels.has_shifts"
        header="Shifts">
        <div
          v-if="accessLevels.has_shifts"
          class="[&>div>span]:text-textColor-soft [&>div]:flex [&>div]:justify-between">
          <div>
            <span>Total</span>
            <strong>{{ totalShifts ?? 0 }} </strong>
          </div>
          <div v-if="unapprovedShifts > 0">
            <span>Not Approved: </span>
            <strong>{{ unapprovedShifts }} </strong>
          </div>
          <div v-if="forSaleShifts">
            <span>Requested/For Sale: </span>
            <strong>{{ forSaleShifts }}</strong>
          </div>

          <div v-if="unassignedShifts > 0">
            <span>Not Assigned: </span>
            <strong>{{ unassignedShifts }} </strong>
          </div>

          <div v-if="declinedShifts > 0">
            <span>Declined: </span>
            <strong>{{ declinedShifts }} </strong>
          </div>
          <div v-if="pendingShifts > 0">
            <span>Pending: </span>
            <strong>{{ pendingShifts }} </strong>
          </div>

          <div v-if="cancelledShifts > 0">
            <span>Cancelled: </span>
            <strong>{{ cancelledShifts }} </strong>
          </div>
        </div>
      </BoxContainer>

      <IntermediateStepPerformanceSection
        v-if="!event.recurring_original_id && event.recurring_dates.length === 0"
        :performance-id="performanceId"
        :festival="festival"
        :invite="invite" />
    </div>
    <div class="other-info relative h-full flex flex-col gap-edge pb-edge">
      <BoxContainer
        header="Description"
        :actions="descriptionActions"
        togglable>
        <TextareaInput
          v-if="editModeDescription"
          v-model="editDescription"
          :min-height="60"
          set-focus
          placeholder="Say something about this event" />

        <DisplayText
          v-else-if="onEvent && $page.props?.event && $page.props?.event?.description"
          :content="$page.props.event.description" />

        <DisplayText
          v-else-if="event.description != null"
          :content="event.description" />
        <span
          v-else
          class="text-textColor-soft">
          No Description Added
        </span>
      </BoxContainer>
      <IntermediateStepAssignments
        v-model:show="showAssignments"
        :invite="invite"
        :on-event="onEvent"
        :admin="admin" />
      <IntermediateStepRoomBookings
        v-if="(venueId || festival) && invite.parent_id === null"
        v-model:show="showRoomBookings"
        :show-recurring-report-downloader="event.recurring_original_id !== null && event.recurring_dates !== null"
        :invite="invite"
        :venue-id="venueId"
        @room-bookings="roomBookings = $event" />

      <IntermediateStepShifts
        v-if="accessLevels.has_shifts"
        v-model:show="showShifts"
        :group-id="groupId"
        :can-edit="admin"
        :invite="invite" />

      <IntermediateStepPartners
        v-if="!festival && accessLevels.has_partners"
        v-model:show="showPartners"
        :group-id="groupId"
        :invite="invite"
        :on-event-page="onEvent"
        :can-edit="admin" />
    </div>
  </div>

  <ShowTimeCRUDModal
    v-if="selectedShowTime"
    :show-time="selectedShowTime"
    :rooms="
      uniqueObjectsFromArray(
        roomBookings.map((b) => {
          return {
            id: b.room_id,
            name: b.room_name,
          };
        })
      )
    "
    :event="event"
    :show-times="invite.show_times"
    @closed="selectedShowTime = null"
    @created="[$emit('reloadInvite'), reloadEventPage()]"
    @updated="[$emit('reloadInvite'), reloadEventPage()]"
    @deleted="[$emit('reloadInvite'), reloadEventPage()]" />

  <EventRequestSlideOut
    v-if="selectedEventRequestId"
    :working="false"
    :event-request-id="selectedEventRequestId"
    :group-id="groupId"
    :venue-id="venueId"
    :on-event="onEvent"
    @closed="selectedEventRequestId = null"
    @show-invite="selectedEventRequestId = null"
    @open-event="[openRoute(getRoute('events.show', invite.event.slug)), (selectedEventRequestId = null)]" />
</template>
